import { memo } from 'react'
import { ContractorDeactivationReason, ContractorStatus } from 'src/constants/contractor/enums'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { ContractorStatusChip } from '../contractor/grid/ContractorStatusChip'
import { OnboardingStatusChip } from '../onboarding/grid/OnboardingStatusChip'

interface Props {
  contractorStatus?: ContractorStatus | null
  deactivationReason?: ContractorDeactivationReason | null
  deactivatedByEmail?: string | null
  deactivatedAt?: string | null
  onboardingStatus?: OnboardingStatus | null
  failedAt?: string | null
  failedSteps?: string[]
  expiredSteps?: string[]
}

const ContractorOnboardingStatusChipComponent = ({
  contractorStatus,
  deactivationReason,
  deactivatedByEmail,
  deactivatedAt,
  onboardingStatus,
  failedAt,
  failedSteps,
  expiredSteps,
}: Props) => {
  if (onboardingStatus && onboardingStatus !== OnboardingStatus.Completed) {
    return (
      <OnboardingStatusChip
        onboardingStatus={onboardingStatus}
        failedAt={failedAt}
        failedSteps={failedSteps}
      />
    )
  }

  if (contractorStatus) {
    return (
      <ContractorStatusChip
        status={contractorStatus}
        deactivationReason={deactivationReason}
        deactivatedByEmail={deactivatedByEmail}
        deactivatedAt={deactivatedAt}
        expiredSteps={expiredSteps}
        failedSteps={failedSteps}
      />
    )
  }

  return null
}

export const ContractorOnboardingStatusChip = memo(ContractorOnboardingStatusChipComponent)
