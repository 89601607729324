import React, { memo } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { FileWithPreview } from 'src/components/shared/FileWithPreview'
import { CollectedData } from 'src/interfaces/collectedData'
import { addS } from 'src/utils/baseUtils'

interface Props {
  collectedData: CollectedData
  header?: any
}

const PDF_FILE_TYPE = 'pdf'

const ContractorStepDocumentsComponent = ({ header, collectedData }: Props) => {
  return collectedData.documents?.length ? (
    <div css={tw`pt-3 px-4`}>
      <Typography css={tw`mb-1.5`} color={colors.SHADES_GREY_400} variant="h6">
        {addS(collectedData.documents.length, 'Attached file')}
      </Typography>
      {collectedData.documents.map((document) => (
        <FileWithPreview
          fullScreen={document.extension === PDF_FILE_TYPE}
          key={document.filePath}
          content={
            document.extension === PDF_FILE_TYPE ? (
              <>
                {header}
                <object
                  data={`${document.filePath}#toolbar=0`}
                  type="application/pdf"
                  css={tw`w-full h-full min-h-[100px]`}
                />
              </>
            ) : (
              <>
                {header}
                <img src={document.filePath} css={tw`max-w-full h-auto`} />
              </>
            )
          }
          previewContent={
            document.extension === PDF_FILE_TYPE ? (
              <object
                data={`${document.filePath}#toolbar=0`}
                type="application/pdf"
                css={tw`w-full h-full mt-[-5px] mx-[-5px]`}
              />
            ) : (
              <img src={document.filePath} css={tw`block w-full h-auto min-h-[100px]`} />
            )
          }
          className={tw`rounded-lg mb-3`}
        />
      ))}
    </div>
  ) : null
}

export const ContractorStepDocuments = memo(ContractorStepDocumentsComponent)
