import React, { memo, useCallback, useContext, MouseEvent } from 'react'
import { HorizontalDotsMinor, ReplayMinor } from '@shopify/polaris-icons'
import { PaymentsContext } from '../Payments.context'
import { Payment } from 'src/interfaces/payment'
import { Button } from 'src/components/shared/Button'
import { PaymentStatus } from 'src/constants/payment/enums'
import { colors } from 'src/theme'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ProcessPayment } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { Tooltip } from '@mui/material'
import tw from 'twin.macro'

interface Props {
  payment: Payment
}

const statusesWithActions = [PaymentStatus.Paid, PaymentStatus.NotPaid]

const PaymentsItemActionButtonComponent = ({ payment }: Props) => {
  const dispatch = useDispatch()

  const { openPaymentsItemAction, refreshPayments } = useContext(PaymentsContext)

  const handleClickAction = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openPaymentsItemAction(event, payment)
    },
    [payment],
  )

  const handleClickRetry = useCallback(async () => {
    try {
      const processedPayment: Payment = await createAsyncAction(
        dispatch,
        ProcessPayment.request({ paymentId: payment.id }),
      )

      await refreshPayments()

      if (processedPayment.status === PaymentStatus.Failed) {
        throw processedPayment.failureReason
      }

      if (processedPayment.status === PaymentStatus.Pending) {
        showToast('Processing your payment', { variant: 'warning' })

        return
      }

      showToast('Payment have proceeded', { variant: 'success' })
    } catch (err) {
      showToast(`Unable to proceed due to an error: ${err}`, { variant: 'error' })
    }
  }, [payment, refreshPayments])

  if (payment.status === PaymentStatus.Failed) {
    return (
      <Tooltip color="black" title="Retry payment" placement="left-end">
        <span css={tw`min-w-0 inline-flex`}>
          <Button
            color="light"
            size="tiny"
            leftIcon={<ReplayMinor fill={colors.PRIMARY_PURPLE_500} width={20} height={20} />}
            onClick={handleClickRetry}
          />
        </span>
      </Tooltip>
    )
  }

  return (
    <Button
      color="white"
      size="tiny"
      disabled={!statusesWithActions.includes(payment.status)}
      leftIcon={<HorizontalDotsMinor width={20} height={20} />}
      onClick={handleClickAction}
    />
  )
}

export const PaymentsItemActionButton = memo(PaymentsItemActionButtonComponent)
