export enum OnboardingStatus {
  Created = 'created',
  Completed = 'completed',
  WaitingForApproval = 'waiting_for_approval',
  Failed = 'failed',
  PartiallyCompleted = 'partially_completed',
}

export enum OnboardingStepStatus {
  Created = 'created',
  InProgress = 'in_progress',
  WaitingForInternalApproval = 'waiting_for_internal_approval',
  WaitingForApproval = 'waiting_for_approval',
  Completed = 'completed',
  Failed = 'failed', // Applies only for BG check
}
