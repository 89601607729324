import { createContext, MouseEvent } from 'react'
import { Onboarding } from 'src/interfaces/onboarding'

export interface IOnboardingsContext {
  searchQuery: string
  setSearchQuery(value: string): void

  tagsFilter: Array<string>
  setTagsFilter(statusFilter: Array<string>): void

  refreshOnboardings(): void

  // edit action
  actionAnchor: null | HTMLElement
  onboardingToAction: Onboarding | null
  openOnboardingsItemAction(event: MouseEvent<HTMLButtonElement>, onboarding: Onboarding): void
  closeOnboardingsItemAction(): void
}

export const OnboardingsContext = createContext<IOnboardingsContext>({
  searchQuery: '',
  setSearchQuery: () => {},

  tagsFilter: [],
  setTagsFilter: () => {},

  refreshOnboardings: () => {},

  actionAnchor: null,
  onboardingToAction: null,
  openOnboardingsItemAction: () => {},
  closeOnboardingsItemAction: () => {},
})
