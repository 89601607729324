import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchContractors,
  FetchContractor,
  FetchContractorsCounts,
  SendRemainderContractor,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { Contractor, ContractorDetails, ContractorsCounts } from 'src/interfaces/contractor'
import { ContractorState, ContractorStatus } from 'src/constants/contractor/enums'
import { Pagination } from 'src/interfaces/table'

export interface FetchContractorsPayload {
  contractorState: ContractorState
  partnerMarketId?: string
  contractorStatus?: ContractorStatus
  tags?: string[]
  contractorSearch?: string
  limit: number
  offset: number
  paymentsAvailableOnly?: boolean
  sort?: string
}

function* onFetchContractors(
  action: AsyncAction<FetchContractorsPayload, { data: Array<Contractor>; pagination: Pagination }>,
) {
  const {
    contractorState,
    partnerMarketId,
    contractorStatus,
    tags,
    contractorSearch,
    paymentsAvailableOnly,
    limit,
    offset,
    sort,
  } = action.payload

  try {
    const query: any = {
      contractorState,
      partnerMarketId,
      contractorStatus,
      tags,
      contractorSearch,
      limit,
      offset,
      sort,
    }

    if (paymentsAvailableOnly) {
      query.paymentsAvailableOnly = `${paymentsAvailableOnly}`
    }

    const { payload }: { payload: { data: Array<Contractor>; pagination: Pagination } } =
      yield call(callSecureApi, Endpoints.FetchContractors, {
        apiService: ApiService.Paraworks,
        query,
      })

    yield put(FetchContractors.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch contractors'

    yield put(FetchContractors.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export interface FetchContractorsCountsPayload {
  partnerMarketId?: string
}

function* onFetchContractorsCounts(
  action: AsyncAction<FetchContractorsCountsPayload, ContractorsCounts>,
) {
  const { partnerMarketId } = action.payload

  try {
    const { payload }: { payload: ContractorsCounts } = yield call(
      callSecureApi,
      Endpoints.FetchContractorsCount,
      {
        apiService: ApiService.Paraworks,
        query: {
          partnerMarketId,
        },
      },
    )

    yield put(FetchContractorsCounts.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch contractors counts'

    yield put(FetchContractorsCounts.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export interface FetchContractorPayload {
  contractorId: string
  contractorState: ContractorState
}

function* onFetchContractor(action: AsyncAction<FetchContractorPayload, ContractorDetails>) {
  const { contractorId, contractorState } = action.payload

  try {
    const { payload }: { payload: any } = yield call(callSecureApi, Endpoints.FetchContractor, {
      apiService: ApiService.Paraworks,
      method: 'GET',
      query: {
        contractorState,
      },
      params: [
        {
          value: contractorId,
          field: ':contractorId',
        },
      ],
    })

    yield put(FetchContractor.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = 'Failed to get contractor'

    yield put(FetchContractor.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SendRemainderContractorPayload {
  partnerDriverId: string
}

function* onSendRemainderContractor(action: AsyncAction<SendRemainderContractorPayload>) {
  const { partnerDriverId } = action.payload

  try {
    yield call(callSecureApi, Endpoints.SendRemainderContractor, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      params: [
        {
          value: partnerDriverId,
          field: ':partnerDriverId',
        },
      ],
    })

    yield put(SendRemainderContractor.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to resend invite'

    yield put(SendRemainderContractor.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* contractorSaga() {
  yield takeLatest(FetchContractors.type.REQUEST, onFetchContractors)
  yield takeEvery(FetchContractorsCounts.type.REQUEST, onFetchContractorsCounts)
  yield takeEvery(FetchContractor.type.REQUEST, onFetchContractor)
  yield takeEvery(SendRemainderContractor.type.REQUEST, onSendRemainderContractor)
}
