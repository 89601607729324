import {
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React, { memo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { Contractor } from 'src/interfaces/contractor'
import tw from 'twin.macro'
import { EmptyContractors } from './EmptyContractors'
import { DropdownMinor } from '@shopify/polaris-icons'
import { GridTableFooter } from 'src/components/shared/grid/GridTableFooter'

const handleGetRowId = (row: Contractor) => row.id

interface Props {
  contractors: Array<Contractor>
  columns: GridColDef[]
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  sortModel?: GridSortModel
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowClick(params: { row: Contractor }): void
}

const ContractorsGridComponent = ({
  columns,
  contractors,
  isLoading,
  rowCount,
  paginationModel,
  sortModel,
  pageSizeOptions,
  onSortModelChange,
  onPaginationModelChange,
  onRowClick,
}: Props) => {
  return (
    <div
      css={[
        tw`w-full h-[400px]`,
        {
          '@media (min-height: 830px)': tw`h-[520px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        rows={contractors}
        columns={columns}
        loading={isLoading}
        rowCount={rowCount}
        getRowId={handleGetRowId}
        onRowClick={onRowClick}
        rowHeight={44}
        columnHeaderHeight={36}
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        paginationModel={paginationModel}
        pageSizeOptions={pageSizeOptions}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          noResultsOverlay: EmptyContractors,
          noRowsOverlay: EmptyContractors,
          columnSortedAscendingIcon: () => (
            <DropdownMinor css={tw`rotate-180`} fill="#655E7C" width={20} />
          ),
          columnSortedDescendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnUnsortedIcon: () => null,
          footer: () => <GridTableFooter pageSizeOptions={pageSizeOptions} />,
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const ContractorsGrid = memo(ContractorsGridComponent)
