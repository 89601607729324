import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { HorizontalDotsMinor, MinusMinor, RefreshMinor, TickMinor } from '@shopify/polaris-icons'
import { CollectedDataStatus } from 'src/constants/collectedData'

interface Props {
  status: CollectedDataStatus
  recollecting?: boolean
}

const recollectingStatus = {
  color: colors.COLORS_ORANGE_500,
  text: 'Recollection',
  icon: <RefreshMinor width={20} fill={colors.COLORS_ORANGE_500} />,
}

const stepStatusMap = {
  [CollectedDataStatus.WaitingForApproval]: {
    color: colors.COLORS_ORANGE_500,
    text: 'Waiting for approval',
    icon: <HorizontalDotsMinor width={20} fill={colors.COLORS_ORANGE_500} />,
  },
  [CollectedDataStatus.Completed]: {
    color: colors.COLORS_GREEN_500,
    text: 'Approved',
    icon: <TickMinor width={20} fill={colors.COLORS_GREEN_500} />,
  },
  [CollectedDataStatus.Created]: {
    color: colors.SHADES_GREY_300,
    text: 'Not Started',
    icon: <MinusMinor width={20} fill={colors.SHADES_GREY_300} />,
  },
  [CollectedDataStatus.Failed]: {
    color: colors.COLORS_RED_500,
    text: 'Failed',
    icon: <HorizontalDotsMinor width={20} fill={colors.COLORS_RED_500} />,
  },
  [CollectedDataStatus.WaitingForInternalApproval]: {
    color: colors.SHADES_GREY_300,
    text: 'Pending',
    icon: <HorizontalDotsMinor width={20} fill={colors.SHADES_GREY_300} />,
  },
  [CollectedDataStatus.InProgress]: {
    color: colors.SHADES_GREY_300,
    text: 'Pending',
    icon: <HorizontalDotsMinor width={20} fill={colors.SHADES_GREY_300} />,
  },
}

const ContractorStepStatusComponent = ({ status, recollecting }: Props) => {
  const params = recollecting ? recollectingStatus : stepStatusMap[status]

  if (!params) {
    return null
  }

  return (
    <div css={tw`flex items-center`}>
      {params.icon}
      <Typography css={tw`ml-1`} variant="body2" color={params.color}>
        {params.text}
      </Typography>
    </div>
  )
}

export const ContractorStepStatus = memo(ContractorStepStatusComponent)
