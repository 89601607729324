import {
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React, { memo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { Onboarding } from 'src/interfaces/onboarding'
import tw from 'twin.macro'
import { EmptyOnboardings } from './EmptyOnboardings'
import { DropdownMinor } from '@shopify/polaris-icons'
import { GridTableFooter } from 'src/components/shared/grid/GridTableFooter'

const handleGetRowId = (row: Onboarding) => row.id

interface Props {
  onboardings: Array<Onboarding>
  columns: GridColDef[]
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  initialSortField?: string
  sortModel?: GridSortModel
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowClick(params: { row: Onboarding }): void
}

const OnboardingsGridComponent = ({
  onboardings,
  columns,
  isLoading,
  paginationModel,
  pageSizeOptions,
  rowCount,
  sortModel,
  onSortModelChange,
  onPaginationModelChange,
  onRowClick,
}: Props) => {
  return (
    <div
      css={[
        tw`w-full h-[400px]`,
        {
          '@media (min-height: 830px)': tw`h-[520px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        rows={onboardings}
        columns={columns}
        loading={isLoading}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        pageSizeOptions={pageSizeOptions}
        rowHeight={44}
        columnHeaderHeight={36}
        sortModel={sortModel}
        sortingMode="server"
        getRowId={handleGetRowId}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={onRowClick}
        onSortModelChange={onSortModelChange}
        slots={{
          noRowsOverlay: EmptyOnboardings,
          noResultsOverlay: EmptyOnboardings,
          columnSortedDescendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnSortedAscendingIcon: () => (
            <DropdownMinor fill="#655E7C" css={tw`rotate-180`} width={20} />
          ),
          columnUnsortedIcon: () => null,
          footer: () => <GridTableFooter pageSizeOptions={pageSizeOptions} />,
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const OnboardingsGrid = memo(OnboardingsGridComponent)
