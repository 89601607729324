import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchOnboardings,
  FetchOnboarding,
  FetchOnboardingsCounts,
  FetchOnboardingTemplates,
  FetchRecollectingSteps,
  SendRemainderOnboarding,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from '../api/callApi'
import { Onboarding, OnboardingCounts, OnboardingTemplate } from 'src/interfaces/onboarding'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { ContractorDetails } from 'src/interfaces/contractor'
import { CollectedDataStepType } from 'src/constants/collectedData'
import { Pagination } from 'src/interfaces/table'

export interface FetchOnboardingsPayload {
  partnerMarketId?: string
  onboardingStatus?: OnboardingStatus
  partnerDriverSearch?: string
  limit?: number
  offset?: number
  tags?: Array<string>
  sort?: string
}

function* onFetchOnboardings(
  action: AsyncAction<FetchOnboardingsPayload, { data: Array<Onboarding>; pagination: Pagination }>,
) {
  const { partnerMarketId, onboardingStatus, partnerDriverSearch, tags, limit, offset, sort } =
    action.payload

  try {
    const { payload }: { payload: { data: Array<Onboarding>; pagination: Pagination } } =
      yield call(callSecureApi, Endpoints.FetchOnboardings, {
        apiService: ApiService.Paraworks,
        query: {
          partnerMarketId,
          onboardingStatus,
          partnerDriverSearch,
          tags,
          limit,
          offset,
          sort,
        },
      })

    yield put(FetchOnboardings.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch onboardings'

    yield put(FetchOnboardings.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchOnboardingPayload {
  onboardingId: string
}

function* onFetchOnboarding(action: AsyncAction<FetchOnboardingPayload, ContractorDetails>) {
  const { onboardingId } = action.payload

  try {
    const { payload }: { payload: ContractorDetails } = yield call(
      callSecureApi,
      Endpoints.FetchOnboarding,
      {
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':onboardingId',
            value: onboardingId,
          },
        ],
      },
    )

    yield put(FetchOnboarding.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch onboardings'

    yield put(FetchOnboarding.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchRecollectingStepsPayload {
  partnerDriverId: string
}

function* onFetchRecollectingSteps(
  action: AsyncAction<FetchRecollectingStepsPayload, { steps: Array<CollectedDataStepType> }>,
) {
  const { partnerDriverId } = action.payload

  try {
    const { payload }: { payload: { steps: Array<CollectedDataStepType> } } = yield call(
      callSecureApi,
      Endpoints.FetchRecollectingSteps,
      {
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':partnerDriverId',
            value: partnerDriverId,
          },
        ],
      },
    )

    yield put(FetchRecollectingSteps.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch recollecting steps'

    yield put(FetchRecollectingSteps.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchOnboardingsCountsPayload {
  partnerMarketId?: string
}

function* onFetchOnboardingsCounts(
  action: AsyncAction<FetchOnboardingsCountsPayload, OnboardingCounts>,
) {
  const { partnerMarketId } = action.payload

  try {
    const { payload }: { payload: OnboardingCounts } = yield call(
      callSecureApi,
      Endpoints.FetchOnboardingsCounts,
      {
        apiService: ApiService.Paraworks,
        query: {
          partnerMarketId,
        },
      },
    )

    yield put(FetchOnboardingsCounts.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch onboardings counts'

    yield put(FetchOnboardingsCounts.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onFetchOnboardingTemplates(action: AsyncAction<void, Array<OnboardingTemplate>>) {
  try {
    const { payload }: { payload: Array<OnboardingTemplate> } = yield call(
      callSecureApi,
      Endpoints.FetchOnboardingTemplates,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchOnboardingTemplates.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch markets'

    yield put(FetchOnboardingTemplates.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SendRemainderOnboardingPayload {
  partnerDriverId: string
}

function* onSendRemainderOnboarding(action: AsyncAction<SendRemainderOnboardingPayload>) {
  const { partnerDriverId } = action.payload

  try {
    yield call(callSecureApi, Endpoints.SendRemainderOnboarding, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      params: [
        {
          value: partnerDriverId,
          field: ':partnerDriverId',
        },
      ],
    })

    yield put(SendRemainderOnboarding.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to resend invite'

    yield put(SendRemainderOnboarding.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingSharedSaga() {
  yield takeEvery(FetchOnboarding.type.REQUEST, onFetchOnboarding)
  yield takeEvery(FetchRecollectingSteps.type.REQUEST, onFetchRecollectingSteps)
  yield takeLatest(FetchOnboardings.type.REQUEST, onFetchOnboardings)
  yield takeEvery(FetchOnboardingsCounts.type.REQUEST, onFetchOnboardingsCounts)
  yield takeEvery(FetchOnboardingTemplates.type.REQUEST, onFetchOnboardingTemplates)
  yield takeEvery(SendRemainderOnboarding.type.REQUEST, onSendRemainderOnboarding)
}
