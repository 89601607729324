import { put, takeEvery, call, select } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchDashboardsUpdates,
  SetDashboardsUpdates,
  ViewDashboardsUpdates,
  FetchFailedPaymentsUpdates,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { getDashboardsUpdates } from 'src/selectors/activities'
import { DashboardsUpdatesItem } from 'src/interfaces/activities'
import { activitiesService } from 'src/services/ActivitiesService'

interface FetchDashboardsUpdatesPayload {
  type: DashboardsUpdatesType
}

function* onFetchDashboardsUpdates(
  action: AsyncAction<FetchDashboardsUpdatesPayload, Array<{ id: string }>>,
) {
  const { type } = action.payload

  try {
    const { payload }: { payload: Array<{ id: string }> } = yield call(
      callSecureApi,
      Endpoints.FetchDashboardsUpdates,
      {
        apiService: ApiService.Paraworks,
        params: [
          {
            value: type,
            field: ':type',
          },
        ],
      },
    )

    yield put(FetchDashboardsUpdates.success(payload))
    yield put(
      SetDashboardsUpdates.success({
        type,
        updatesItem: {
          currentIds: payload.map((item) => item.id),
        },
      }),
    )

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch updates'

    yield put(FetchDashboardsUpdates.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onFetchFailedPaymentsUpdates(action: AsyncAction<void, { data: Array<{ id: string }> }>) {
  try {
    const { payload }: { payload: { data: Array<{ id: string }> } } = yield call(
      callSecureApi,
      Endpoints.FetchFailedPaymentsUpdates,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchFailedPaymentsUpdates.success(payload))
    yield put(
      SetDashboardsUpdates.success({
        type: DashboardsUpdatesType.FailedPayments,
        updatesItem: {
          currentIds: payload.data.map((item) => item.id),
        },
      }),
    )

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch updates'

    yield put(FetchFailedPaymentsUpdates.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ViewDashboardsUpdatesPayload {
  type: DashboardsUpdatesType
}

function* onViewDashboardsUpdates(action: AsyncAction<ViewDashboardsUpdatesPayload>) {
  const { type } = action.payload

  const updatesItems: DashboardsUpdatesItem = yield select(getDashboardsUpdates(type))

  const newViewedIds = activitiesService.setViewedUpdates(type, [...updatesItems.currentIds])

  yield put(
    SetDashboardsUpdates.success({
      type,
      updatesItem: {
        currentIds: [],
        viewedIds: newViewedIds,
      },
    }),
  )
  yield put(ViewDashboardsUpdates.success())
}

export function* activitiesSaga() {
  yield takeEvery(FetchDashboardsUpdates.type.REQUEST, onFetchDashboardsUpdates)
  yield takeEvery(FetchFailedPaymentsUpdates.type.REQUEST, onFetchFailedPaymentsUpdates)
  yield takeEvery(ViewDashboardsUpdates.type.REQUEST, onViewDashboardsUpdates)
}
