import { ContractorStatus } from 'src/constants/contractor/enums'

export const getContractorStatus = (contractor: {
  expiringSoonDocuments?: boolean | null
  isActive?: boolean | null
}) => {
  if (contractor.isActive === false) {
    return ContractorStatus.Deactivated
  }

  if (contractor.expiringSoonDocuments) {
    return ContractorStatus.ExpiresSoon
  }

  if (contractor.isActive === true) {
    return ContractorStatus.Active
  }

  return ContractorStatus.Unknown
}
