import { GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import { formatCurrencyFromCents } from './transformers'

class GridFormatters {
  formatLongDate(params: GridValueFormatterParams<dayjs.ConfigType>): string {
    return params.value ? dayjs(params.value).format('LLL') : '-'
  }

  formatTimestamp(params: GridValueFormatterParams<number>): string {
    return params.value ? dayjs.unix(params.value).format('LLL') : '-'
  }

  formatCurrency(params: GridValueFormatterParams<number>): string {
    return formatCurrencyFromCents(params.value)
  }

  addCurrencySign(params: GridValueFormatterParams<string | number>): string {
    return `$${params.value}`
  }

  formatShortDate(params: GridValueFormatterParams<dayjs.ConfigType>): string {
    if (!params.value) {
      return '-'
    }

    const date = dayjs(params.value)
    const format = date.isSame(dayjs(), 'year') ? 'MMM D, LT' : 'LLL'

    return date.format(format)
  }
}

export const gridFormatters = new GridFormatters()

export class GridComparators {}

export const gridComparators = new GridComparators()

export class GridValueGetters {
  parseDate(params: GridValueGetterParams<any, string>): Date | null {
    return params.value ? dayjs(params.value).toDate() : null
  }

  parseTimestamp(value?: number | null): Date | null {
    return value ? dayjs.unix(value).toDate() : null
  }
}

export const gridValueGetters = new GridValueGetters()
