import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { OnboardingsGrid } from './OnboardingsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Onboarding } from 'src/interfaces/onboarding'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  FetchOnboarding,
  FetchOnboardings,
  FetchOnboardingsCounts,
} from 'src/constants/actionTypes'
import {
  onboardingPageSizeOptions,
  onboardingInitialSortFields,
  onboardingColumns,
} from 'src/constants/onboarding/onboardingGrid'
import { IOnboardingsContext, OnboardingsContext } from './Onboardings.context'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getPartnerId } from 'src/selectors/user'
import { getSelectedMarket } from 'src/selectors/market'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { OnboardingsHeader } from './OnboardingsHeader'
import { ContractorDetailsModal } from 'src/components/contractor-details/ContractorDetailsModal'
import { OnboardingsItemActionMenu } from './action/OnboardingsItemActionMenu'
import { ContractorDetails } from 'src/interfaces/contractor'
import { handleApiError } from 'src/utils/errorHandlers'
import { Pagination } from 'src/interfaces/table'

interface FetchOnboardingsParams {
  limit?: number
  offset?: number
  selectedStatus: OnboardingStatus
  searchQuery: string
  tagsFilter: string[]
  sortModel?: GridSortModel
}

interface OnboardingsTableProps {
  selectedStatus: OnboardingStatus
  openManageTags: () => void
}

const OnboardingsTableComponent = ({ selectedStatus, openManageTags }: OnboardingsTableProps) => {
  const dispatch = useDispatch()

  const partnerId = useSelector(getPartnerId)
  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset, setPaginationModel } =
    useGridPagination(onboardingPageSizeOptions)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [onboardings, setOnboardings] = useState<Array<Onboarding>>([])
  const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null)
  const [onboardingToAction, setOnboardingToAction] = useState<Onboarding | null>(null)
  const [selectedOnboardingId, setSelectedOnboardingId] = useState<string | null>(null)
  const [contractorDetails, setContractorDetails] = useState<ContractorDetails | null>(null)
  const [counts, setCounts] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [tagsFilter, setTagsFilter] = useState<Array<string>>([])
  const [sortModel, setSortModel] = useState<GridSortModel>(
    onboardingInitialSortFields[selectedStatus]
      ? [
          {
            field: onboardingInitialSortFields[selectedStatus] || 'invitedAt',
            sort: 'desc',
          },
        ]
      : [],
  )

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  const fetchOnboardings = useCallback(
    async ({
      limit,
      offset,
      searchQuery,
      tagsFilter,
      sortModel,
      selectedStatus,
    }: FetchOnboardingsParams) => {
      if (!partnerId) {
        return
      }

      setIsLoading(true)

      try {
        const params: any = {
          partnerId,
          limit,
          offset,
          onboardingStatus: selectedStatus,
        }

        if (searchQuery) {
          params.partnerDriverSearch = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        if (tagsFilter?.length) {
          params.tags = tagsFilter
        }

        if (sortModel && sortModel[0]) {
          params.sort = `${sortModel[0].field}:${sortModel[0].sort?.toUpperCase()}`
        }

        const payload: { data: Array<Onboarding>; pagination: Pagination } =
          await createAsyncAction(dispatch, FetchOnboardings.request(params))

        setOnboardings(payload.data)
        setCounts(payload.pagination.total)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [partnerId, selectedMarket?.id],
  )

  const fetchOnboardingCounts = useCallback(() => {
    if (!partnerId) {
      return
    }

    const params: any = { partnerId }

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchOnboardingsCounts.request(params))
  }, [partnerId, selectedMarket?.id])

  const refreshOnboardings = useCallback(async () => {
    await fetchOnboardings({
      limit,
      offset,
      searchQuery,
      tagsFilter,
      sortModel,
      selectedStatus,
    })
    fetchOnboardingCounts()
  }, [
    limit,
    offset,
    searchQuery,
    tagsFilter,
    sortModel,
    selectedStatus,
    fetchOnboardings,
    fetchOnboardingCounts,
  ])

  useEffect(() => {
    fetchOnboardings({
      limit,
      offset,
      searchQuery,
      tagsFilter,
      sortModel,
      selectedStatus,
    })
  }, [
    limit,
    offset,
    partnerId,
    selectedMarket?.id,
    searchQuery,
    tagsFilter,
    sortModel,
    selectedStatus,
  ])

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const openOnboardingsItemAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, onboardingToEdit: Onboarding) => {
      setActionAnchor(event.currentTarget)
      setOnboardingToAction(onboardingToEdit)
    },
    [],
  )

  const closeOnboardingsItemAction = useCallback(() => {
    setActionAnchor(null)
    setOnboardingToAction(null)
  }, [])

  const onboardingsContextValue = useMemo<IOnboardingsContext>(() => {
    return {
      searchQuery,
      setSearchQuery,

      tagsFilter,
      setTagsFilter,

      refreshOnboardings,

      actionAnchor,
      onboardingToAction,
      openOnboardingsItemAction,
      closeOnboardingsItemAction,
    }
  }, [
    searchQuery,
    setSearchQuery,

    tagsFilter,
    setTagsFilter,

    refreshOnboardings,

    actionAnchor,
    onboardingToAction,
    openOnboardingsItemAction,
    closeOnboardingsItemAction,
  ])

  const handleRowClick = useCallback(({ row }: { row: Onboarding }) => {
    setSelectedOnboardingId(row.onboardingId)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setSelectedOnboardingId(null)
  }, [])

  const fetchContractorDetails = useCallback(async (onboardingId: string) => {
    try {
      const contractor: ContractorDetails = await createAsyncAction(
        dispatch,
        FetchOnboarding.request({ onboardingId }),
      )

      setContractorDetails(contractor)
    } catch (err) {
      handleApiError(err)
    }
  }, [])

  useEffect(() => {
    if (!selectedOnboardingId) {
      setContractorDetails(null)
    } else {
      fetchContractorDetails(selectedOnboardingId)
    }
  }, [selectedOnboardingId])

  const refreshOnboardingDetails = useCallback(async () => {
    if (selectedOnboardingId) {
      await fetchContractorDetails(selectedOnboardingId)
    }
    refreshOnboardings()
  }, [selectedOnboardingId, refreshOnboardings])

  const columns = useMemo(() => onboardingColumns[selectedStatus], [selectedStatus])

  return (
    <OnboardingsContext.Provider value={onboardingsContextValue}>
      <OnboardingsHeader openManageTags={openManageTags} />
      <OnboardingsGrid
        onboardings={onboardings}
        columns={columns}
        isLoading={isLoading}
        paginationModel={paginationModel}
        pageSizeOptions={onboardingPageSizeOptions}
        rowCount={counts}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handleChangePagination}
        onRowClick={handleRowClick}
      />
      <OnboardingsItemActionMenu />
      {contractorDetails && (
        <ContractorDetailsModal
          contractorDetails={contractorDetails}
          flow="onboardings"
          onClose={handleCloseDetails}
          onRefresh={refreshOnboardingDetails}
        />
      )}
    </OnboardingsContext.Provider>
  )
}

export const OnboardingsTable = memo(OnboardingsTableComponent)
