import React, { useMemo } from 'react'
import { OutlinedTextFieldProps, TextField, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import MaskedInput from 'react-text-mask'
import { PasswordTextField } from './PasswordTextField'

const TextMaskCustom = React.forwardRef<HTMLInputElement, any>(function TextMaskCustom(
  { mask, ...props },
  ref,
) {
  return <MaskedInput {...props} mask={mask} guide={false} inputRef={ref} />
})

export interface BasicTextFieldProps<T>
  extends Omit<OutlinedTextFieldProps, 'name' | 'variant' | 'label'> {
  name: keyof T
  label?: string
  mask?: Array<string | RegExp>
}

export const BasicTextField = <T extends unknown>({
  name,
  type,
  label,
  css,
  mask,
  ...rest
}: BasicTextFieldProps<T>) => {
  const InputComponent = useMemo(
    () => (type === 'password' ? PasswordTextField : TextField),
    [type],
  )

  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <InputComponent
        name={name as string}
        type={type}
        variant="outlined"
        placeholder={label}
        {...rest}
        InputProps={
          mask
            ? {
                inputComponent: TextMaskCustom as any,
              }
            : undefined
        }
        inputProps={{
          mask,
        }}
      />
    </div>
  )
}
