import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { OnboardingStatus } from './enums'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Chip } from 'src/components/shared/Chip'
import { Onboarding } from 'src/interfaces/onboarding'
import { StepsChip } from 'src/components/onboarding/grid/StepsChip'
import { TableTags } from 'src/components/tags/TableTags'
import { OnboardingsItemActionButton } from 'src/components/onboarding/grid/action/OnboardingsItemActionButton'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { Tag } from 'src/interfaces/tag'
import { OnboardingStatusChip } from 'src/components/onboarding/grid/OnboardingStatusChip'
import { getOnboardingTableStepTitle } from 'src/utils/collectedData'

const sharedOnboardingsColumns: Array<GridColDef> = [
  {
    field: 'contractorId',
    headerName: 'Contractors ID',
    flex: 3,
    sortable: true,
  },
  {
    field: 'contractorName',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
  },
]

const lastOnboardingColumns: Array<GridColDef> = [
  {
    field: 'phone',
    headerName: 'Phone number',
    flex: 2,
    sortable: true,
  },
  {
    field: 'tags',
    headerName: 'Tag(s)',
    flex: 3,
    sortable: false,
    sortComparator: (v1: Tag[], v2: Tag[]) => {
      return v1.length - v2.length
    },
    renderCell: (params: GridRenderCellParams<Onboarding>) =>
      params.row?.tags.length ? <TableTags tags={params.row?.tags || []} /> : <span>-</span>,
  },
]

const invitedContractorsTable = [
  ...sharedOnboardingsColumns,
  ...lastOnboardingColumns,
  {
    field: 'invitedAt',
    headerName: 'Invited at',
    flex: 2,
    sortable: true,
    type: 'date',
    valueGetter: gridValueGetters.parseDate,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'completed_steps',
    headerName: 'Steps completed',
    flex: 2,
    sortable: false,
    sortComparator: (v1: string, v2: string) => {
      return +v1.split(' of ')[0] - +v2.split(' of ')[0]
    },
    valueGetter: (params: GridValueGetterParams<Onboarding>) =>
      `${params.row.stepsCompleted} of ${params.row.totalSteps}`,
    renderCell: (params: GridRenderCellParams<Onboarding>) => <Chip text={params.value} />,
  },
]

export const onboardingColumns = {
  [OnboardingStatus.Created]: invitedContractorsTable,
  [OnboardingStatus.PartiallyCompleted]: invitedContractorsTable,
  [OnboardingStatus.WaitingForApproval]: [
    ...sharedOnboardingsColumns,
    ...lastOnboardingColumns,
    {
      field: 'documentsToReview',
      headerName: 'Documents to review',
      flex: 3,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<Onboarding>) => {
        return params.value.map(getOnboardingTableStepTitle)
      },
      renderCell: (params: GridRenderCellParams<Onboarding>) => <StepsChip titles={params.value} />,
    },
    {
      field: 'invitedAt',
      headerName: 'Invited at',
      flex: 2,
      sortable: true,
      type: 'date',
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [OnboardingStatus.Completed]: [
    ...sharedOnboardingsColumns,
    ...lastOnboardingColumns,
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1.5,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<Onboarding>) => {
        return params?.value ? 'Active' : 'Inactive'
      },
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <Chip
          className={params?.value === 'Active' ? tw`bg-[#04C523]` : tw`bg-[#E33914]`}
          text={params?.value}
          textColor={colors.GRAY_WHITE}
        />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 3,
      sortable: true,
    },
    {
      field: 'dateOnboarded',
      headerName: 'Date onboarded',
      flex: 2,
      sortable: true,
      type: 'date',
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <OnboardingsItemActionButton onboarding={params.row} />
      ),
    },
  ],
  [OnboardingStatus.Failed]: [
    ...sharedOnboardingsColumns,
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Onboarding>) => {
        return (
          <OnboardingStatusChip
            onboardingStatus={params.row.status}
            // failedAt={params.row.onboardingFailedAt}
            failedSteps={params.row.failedSteps}
          />
        )
      },
    },
    ...lastOnboardingColumns,
    {
      field: 'failedSteps',
      headerName: 'Failed on stage',
      flex: 3,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<Onboarding>) => {
        return params.value.map(getOnboardingTableStepTitle)
      },
      renderCell: (params: GridRenderCellParams<Onboarding>) => <StepsChip titles={params.value} />,
    },
  ],
}

export const onboardingInitialSortFields = {
  [OnboardingStatus.Created]: 'invitedAt',
  [OnboardingStatus.WaitingForApproval]: 'invitedAt',
  [OnboardingStatus.Completed]: 'dateOnboarded',
  [OnboardingStatus.Failed]: undefined,
  [OnboardingStatus.PartiallyCompleted]: undefined,
}

export const onboardingPageSizeOptions = [100, 250, 500, 1000]

export const ONBOARDINGS_COLUMNS_KEY = 'onboardingsColumns'
