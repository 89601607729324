import React, { Fragment, useCallback, useMemo, useState } from 'react'
import tw, { css } from 'twin.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import {
  BalanceMajor,
  ChatMajor,
  ChecklistAlternateMajor,
  ChevronRightMinor,
  CustomersMajor,
} from '@shopify/polaris-icons'
import { useSelector } from 'react-redux'
import { getAccessInfo } from 'src/selectors/user'
import { UserPermission, UserProductName } from 'src/constants/user'
import { Button } from '../shared/Button'
import { SupportModal } from '../shared/SupportModal'

interface ButtonParams {
  label?: string
  icon?: any
  selected?: boolean
  type: 'navigate' | 'separator' | 'action'
  onClick?: () => void
}

export const SideMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const accessInfo = useSelector(getAccessInfo)

  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)

  const handleNavigate = useCallback((route: string) => {
    navigate(route)
  }, [])

  const handleCloseSupportModal = useCallback(() => {
    setIsSupportModalOpen(false)
  }, [])

  const buttons = useMemo(() => {
    const res: ButtonParams[] = [
      {
        type: 'navigate',
        label: 'Contractors',
        selected: ROUTES.CONTRACTORS_URL.includes(location.pathname),
        icon: <CustomersMajor height={20} width={20} />,
        onClick: () => {
          handleNavigate(ROUTES.CONTRACTORS_URL)
        },
      },
      {
        type: 'navigate',
        label: 'Onboarding',
        selected: ROUTES.ONBOARDING_URL.includes(location.pathname),
        icon: <ChecklistAlternateMajor height={20} width={20} />,
        onClick: () => {
          handleNavigate(ROUTES.ONBOARDING_URL)
        },
      },
    ]

    if (
      accessInfo?.permission?.includes(UserPermission.PaymentsView) &&
      accessInfo?.products?.some((item) => item.product === UserProductName.Payments)
    ) {
      res.push({
        type: 'navigate',
        label: 'Payments',
        selected: ROUTES.PAYMENTS_URL.includes(location.pathname),
        icon: <BalanceMajor height={20} width={20} />,
        onClick: () => {
          handleNavigate(ROUTES.PAYMENTS_URL)
        },
      })
    }

    res.push({
      type: 'separator',
      label: 'separator1',
    })
    res.push({
      type: 'action',
      label: 'Support',
      icon: <ChatMajor height={20} width={20} />,
      onClick: () => {
        setIsSupportModalOpen(true)
      },
    })

    return res
  }, [accessInfo, location.pathname, handleNavigate])

  return (
    <div css={tw`w-[204px] bg-[#EDEDED] flex justify-between flex-col`}>
      <div css={tw`py-6 px-4`}>
        {buttons.map(({ icon, selected, onClick, label, type }) => (
          <Fragment key={label}>
            {type === 'separator' ? (
              <div css={tw`my-4 h-[1px] w-full bg-[#DAD9E0]`} />
            ) : (
              <Button
                leftIcon={icon}
                color={selected ? 'white' : 'grey'}
                className={css(tw`mb-2 w-full justify-start hover:bg-[#F6F6F6]`)}
                size="small"
                rightIcon={
                  type === 'action' ? (
                    <ChevronRightMinor width={20} height={20} fill="#B4AFC0" />
                  ) : undefined
                }
                onClick={onClick}
              >
                {label}
              </Button>
            )}
          </Fragment>
        ))}
      </div>
      <SupportModal isOpen={isSupportModalOpen} onClose={handleCloseSupportModal} />
    </div>
  )
}
