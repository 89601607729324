import { memo, useCallback } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { DataRow } from 'src/components/shared/DataRow'
import { ContractorDetails } from 'src/interfaces/contractor'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { DriverAddTag, DriverDeleteTag, UpdatePartnerDriver } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { Tags } from 'src/components/tags/Tags'
import { Tag } from 'src/interfaces/tag'
import { handleApiError } from 'src/utils/errorHandlers'
import { ContractorOnboardingStatusChip } from './ContractorOnboardingStatusChip'
import { getContractorStatus } from 'src/utils/contractor'
import { OnboardingStatus } from 'src/constants/onboarding/enums'

interface Props {
  contractorDetails: ContractorDetails
  onboardingStatus: OnboardingStatus
  onboardingFailedAt?: string | null
  onboardingFailedSteps?: string[]
  onboardingExpiredSteps?: string[]
  onUpdate(): void | Promise<void>
}

const ContractorPartnerDriverInfoComponent = ({
  contractorDetails,
  onboardingStatus,
  onboardingFailedAt,
  onboardingFailedSteps,
  onboardingExpiredSteps,
  onUpdate,
}: Props) => {
  const dispatch = useDispatch()

  const handleEditExternalId = useCallback(
    async (externalId: string) => {
      if (externalId === contractorDetails.externalId) {
        return
      }

      try {
        await createAsyncAction(
          dispatch,
          UpdatePartnerDriver.request({ partnerDriverId: contractorDetails.id, externalId }),
        )

        await onUpdate()

        showToast('Contractor ID saved.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate],
  )

  const handleSelectTag = useCallback(
    async (newTag: Tag) => {
      try {
        await createAsyncAction(
          dispatch,
          DriverAddTag.request({
            tagId: newTag.id,
            partnerDriverId: contractorDetails.id,
          }),
        )

        await onUpdate()

        showToast('Tag is added.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate],
  )

  const handleRemoveTag = useCallback(async (removedTag: Tag) => {
    try {
      await createAsyncAction(
        dispatch,
        DriverDeleteTag.request({
          tagId: removedTag.id,
          partnerDriverId: contractorDetails.id,
        }),
      )

      await onUpdate()

      showToast('Tag is removed.')
    } catch (err: any) {
      handleApiError(err)
    }
  }, [])

  return (
    <div css={tw`flex p-4`}>
      <div css={tw`bg-white rounded-lg self-baseline w-full`}>
        <div css={tw`p-4`}>
          <Typography variant="h2">{`${contractorDetails.firstName} ${contractorDetails.lastName}`}</Typography>
        </div>
        <DataRow
          title="Contractor status"
          direction="row"
          className={tw`border-t-0`}
          component={
            <ContractorOnboardingStatusChip
              contractorStatus={getContractorStatus(contractorDetails)}
              deactivationReason={contractorDetails.deactivationReason}
              deactivatedAt={contractorDetails.deactivatedAt}
              deactivatedByEmail={contractorDetails.deactivatedByEmail}
              onboardingStatus={onboardingStatus}
              failedAt={onboardingFailedAt}
              failedSteps={onboardingFailedSteps}
              expiredSteps={onboardingExpiredSteps}
            />
          }
        />
        {contractorDetails.id && (
          <DataRow direction="column" title="ID" text={contractorDetails.id} />
        )}
        <DataRow
          title="Contractor ID"
          text={contractorDetails.externalId}
          onEdit={handleEditExternalId}
        />
        {contractorDetails?.businessInfo?.businessName && (
          <DataRow
            direction="column"
            title="Business name"
            text={contractorDetails.businessInfo.businessName}
          />
        )}
        {contractorDetails.phone && (
          <DataRow direction="column" title="Phone number" text={contractorDetails.phone} />
        )}
        {contractorDetails.email && (
          <DataRow direction="column" title="Email" text={contractorDetails.email} />
        )}
        {contractorDetails?.collectDataTemplate?.name ? (
          <DataRow
            direction="column"
            title="Assigned Template"
            text={contractorDetails.collectDataTemplate?.name}
          />
        ) : null}
        {contractorDetails?.partnerMarket ? (
          <DataRow
            direction="column"
            title="Market"
            text={contractorDetails.partnerMarket?.title}
          />
        ) : null}
        {contractorDetails?.referral ? (
          <DataRow
            direction="column"
            title="Referral contractor"
            text={contractorDetails.referral}
          />
        ) : null}
        <Tags
          tags={contractorDetails.tags}
          className={tw`p-4 border-0 border-t border-solid border-[#EDEDED]`}
          onSelectTag={handleSelectTag}
          onRemoveTag={handleRemoveTag}
        />
      </div>
    </div>
  )
}

export const ContractorPartnerDriverInfo = memo(ContractorPartnerDriverInfoComponent)
