import { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { OnboardingStatus } from 'src/constants/onboarding/enums'

interface Props {
  title: string
  subtitle: string
  icon: any
  type: OnboardingStatus
  selected?: boolean
  onClick(type: OnboardingStatus): void
}

const OnboardingTabComponent = ({ title, subtitle, icon, type, selected, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(type)
  }, [type, onClick])

  return (
    <div
      css={[
        tw`flex py-3 px-6 items-center hover:opacity-100 hover:border-b-[3px] opacity-60 cursor-pointer w-[236px] border-0 border-solid border-r border-r-[#EDEDED] border-b-[#B4AFC0]`,
        selected &&
          tw`bg-[#F2E5FF] opacity-100 border-0 border-b-[3px] border-solid border-b-[#5F00BF]`,
      ]}
      onClick={handleClick}
    >
      {icon}
      <div css={tw`ml-4`}>
        <Typography
          css={tw`overflow-hidden [display: -webkit-box] [-webkit-line-clamp: 1] [line-clamp: 1] [-webkit-box-orient: vertical]`}
          variant="h6"
          color={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK}
        >
          {title}
        </Typography>
        <Typography
          css={tw`overflow-hidden [display: -webkit-box] [-webkit-line-clamp: 1] [line-clamp: 1] [-webkit-box-orient: vertical]`}
          color={selected ? colors.PRIMARY_PURPLE_500 : colors.GRAY_BLACK}
          variant="body2"
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

export const OnboardingTab = memo(OnboardingTabComponent)
