import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ContractorsGrid } from './ContractorsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Contractor, ContractorDetails } from 'src/interfaces/contractor'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  FetchContractor,
  FetchContractors,
  FetchContractorsCounts,
} from 'src/constants/actionTypes'
import {
  contractorPageSizeOptions,
  contractorInitialSortFields,
  contractorColumns,
} from 'src/constants/contractor/contractorGrid'
import { IContractorsContext, ContractorsContext } from './Contractors.context'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getSelectedMarket } from 'src/selectors/market'
import { ContractorsHeader } from './ContractorsHeader'
import { ContractorState, ContractorStatus } from 'src/constants/contractor/enums'
import { ContractorDetailsModal } from 'src/components/contractor-details/ContractorDetailsModal'
import { handleApiError } from 'src/utils/errorHandlers'
import { Pagination } from 'src/interfaces/table'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'

interface FetchContractorsParams {
  limit?: number
  offset?: number
  searchQuery: string
  tagsFilter: Array<string>
  statusFilter: Array<ContractorStatus>
  sortModel: GridSortModel
  selectedState: ContractorState
}

interface ContractorsTableProps {
  selectedState: ContractorState
  openManageTags: () => void
}

const ContractorsTableComponent = ({ selectedState, openManageTags }: ContractorsTableProps) => {
  const dispatch = useDispatch()

  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset, setPaginationModel } =
    useGridPagination(contractorPageSizeOptions)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contractors, setContractors] = useState<Array<Contractor>>([])
  const [totalContractors, setTotalContractors] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<Array<ContractorStatus>>([])
  const [tagsFilter, setTagsFilter] = useState<Array<string>>([])
  const [selectedContractorId, setSelectedContractorId] = useState<string | null>(null)
  const [contractorDetails, setContractorDetails] = useState<ContractorDetails | null>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>(
    contractorInitialSortFields[selectedState]
      ? [
          {
            field: contractorInitialSortFields[selectedState] || 'invitedAt',
            sort: 'desc',
          },
        ]
      : [],
  )

  const fetchContractors = useCallback(
    async ({
      limit,
      offset,
      searchQuery,
      sortModel,
      selectedState,
      tagsFilter,
      statusFilter,
    }: FetchContractorsParams) => {
      setIsLoading(true)

      try {
        const params: any = {
          limit,
          offset,
          contractorState: selectedState,
          contractorStatus: statusFilter,
        }

        if (searchQuery) {
          params.contractorSearch = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        if (tagsFilter?.length) {
          params.tags = tagsFilter
        }

        if (sortModel && sortModel[0]) {
          params.sort = `${sortModel[0].field}:${sortModel[0].sort?.toUpperCase()}`
        }

        const payload: { data: Array<Contractor>; pagination: Pagination } =
          await createAsyncAction(dispatch, FetchContractors.request(params))

        setContractors(payload.data)
        setTotalContractors(payload.pagination.total)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [selectedMarket?.id],
  )

  const fetchContractorsCounts = useCallback(() => {
    const params: any = {}

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchContractorsCounts.request(params))
  }, [selectedMarket?.id])

  const refreshContractors = useCallback(async () => {
    await fetchContractors({
      limit,
      offset,
      searchQuery,
      sortModel,
      tagsFilter,
      statusFilter,
      selectedState,
    })
    fetchContractorsCounts()
  }, [
    limit,
    offset,
    searchQuery,
    sortModel,
    tagsFilter,
    statusFilter,
    selectedState,
    fetchContractors,
    fetchContractorsCounts,
  ])

  useEffect(() => {
    fetchContractors({
      limit,
      offset,
      searchQuery,
      sortModel,
      tagsFilter,
      statusFilter,
      selectedState,
    })
  }, [
    limit,
    offset,
    searchQuery,
    sortModel,
    tagsFilter,
    statusFilter,
    selectedState,
    selectedMarket?.id,
  ])

  const handleRowClick = useCallback(({ row }: { row: Contractor }) => {
    setSelectedContractorId(row.id)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setSelectedContractorId(null)
  }, [])

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const contractorsContextValue = useMemo<IContractorsContext>(() => {
    return {
      searchQuery,
      setSearchQuery,

      statusFilter,
      setStatusFilter,

      tagsFilter,
      setTagsFilter,

      refreshContractors,
    }
  }, [
    searchQuery,
    setSearchQuery,

    statusFilter,
    setStatusFilter,

    tagsFilter,
    setTagsFilter,

    refreshContractors,
  ])

  const fetchContractorDetails = useCallback(
    async (contractorId: string) => {
      try {
        const contractor: ContractorDetails = await createAsyncAction(
          dispatch,
          FetchContractor.request({ contractorId, contractorState: selectedState }),
        )
        setContractorDetails(contractor)
      } catch (err) {
        handleApiError(err)
      }
    },
    [selectedState],
  )

  useEffect(() => {
    if (!selectedContractorId) {
      setContractorDetails(null)
    } else {
      fetchContractorDetails(selectedContractorId)
    }
  }, [selectedContractorId])

  const refreshContractorDetails = useCallback(async () => {
    if (selectedContractorId) {
      await fetchContractorDetails(selectedContractorId)
    }
    refreshContractors()
  }, [selectedContractorId, refreshContractors])

  const columns = useMemo(() => contractorColumns[selectedState], [selectedState])

  return (
    <ContractorsContext.Provider value={contractorsContextValue}>
      <ContractorsHeader selectedContractorState={selectedState} openManageTags={openManageTags} />
      <ContractorsGrid
        contractors={contractors}
        isLoading={isLoading}
        columns={columns}
        paginationModel={paginationModel}
        pageSizeOptions={contractorPageSizeOptions}
        rowCount={totalContractors}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handleChangePagination}
        onRowClick={handleRowClick}
      />
      {contractorDetails && (
        <ContractorDetailsModal
          contractorDetails={contractorDetails}
          showWaitingForApprovalStepsFirst={selectedState === ContractorState.Recollection}
          flow="contractors"
          onClose={handleCloseDetails}
          onRefresh={refreshContractorDetails}
        />
      )}
    </ContractorsContext.Provider>
  )
}

export const ContractorsTable = memo(ContractorsTableComponent)
