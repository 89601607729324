import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Payment } from 'src/interfaces/payment'
import { PaymentStatusChip } from 'src/components/payment/grid/PaymentStatusChip'
import { PaymentsItemActionButton } from 'src/components/payment/grid/action/PaymentsItemActionButton'
import { Typography } from '@mui/material'
import { PaymentFailureReasonTooltip } from 'src/components/payment/PaymentFailureReasonTooltip'
import tw from 'twin.macro'
import { PaymentStatus } from './enums'

export const paymentColumns: Array<GridColDef> = [
  {
    field: 'partnerDriverExternalId',
    headerName: 'Contractors ID',
    sortable: true,
    flex: 2,
  },
  {
    field: 'name',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
  },
  {
    field: 'uploadedAt',
    headerName: 'Added on',
    flex: 2,
    sortable: true,
    type: 'dateTime',
    valueGetter: gridValueGetters.parseDate,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams<Payment>) => (
      <div css={tw`flex items-center`}>
        <PaymentStatusChip status={params.value} />
        {params.row.status === PaymentStatus.Failed && !!params.row.failureReasonType && (
          <PaymentFailureReasonTooltip payment={params.row}>
            <Typography fontWeight={500} variant="subtitle1" color="black" css={tw`ml-2 underline`}>
              Details
            </Typography>
          </PaymentFailureReasonTooltip>
        )}
      </div>
    ),
  },
  {
    field: 'paidAt',
    headerName: 'Paid on date',
    flex: 2,
    sortable: true,
    type: 'dateTime',
    valueGetter: gridValueGetters.parseDate,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'payoutMethod',
    headerName: 'Payout Method',
    flex: 2,
    sortable: true,
  },
  {
    field: 'paid_to',
    headerName: 'Paid to',
    flex: 2,
    sortable: false,
    valueGetter: (params: GridValueGetterParams<Payment>) =>
      `${params.row.destinationType || ''}${
        params.row.destinationLast4 ? ` •• ${params.row.destinationLast4}` : ''
      }` || '-',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1.5,
    sortable: true,
    valueFormatter: gridFormatters.formatCurrency,
  },
  {
    field: 'netAmount',
    headerName: 'Net amount',
    flex: 1.5,
    sortable: true,
    valueFormatter: gridFormatters.formatCurrency,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    sortable: false,
    disableExport: true,
    renderCell: (params: GridRenderCellParams<Payment>) => (
      <PaymentsItemActionButton payment={params.row} />
    ),
  },
]

export const paymentPageSizeOptions = [100, 250, 500, 1000]

export const ONBOARDINGS_COLUMNS_KEY = 'paymentsColumns'
