import { memo, ReactNode } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export type ButtonSize = 'large' | 'medium' | 'small' | 'tiny'
export type ButtonColor =
  | 'primary'
  | 'light'
  | 'dark'
  | 'grey'
  | 'black'
  | 'white'
  | 'red'
  | 'green'

export interface ButtonProps extends Omit<LoadingButtonProps, 'color' | 'size'> {
  children?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  size?: ButtonSize
  color?: ButtonColor
  round?: boolean
  selected?: boolean
  className?: any
}

const sizeStyles = {
  large: tw`h-[56px]`,
  medium: tw`h-[42px]`,
  small: tw`h-[36px]`,
  tiny: tw`h-[28px] rounded`,
}

const colorsStyles = {
  primary: tw`bg-[#7F00FF] disabled:bg-[#7F00FF] hover:bg-[#5F00BF] active:bg-[#440B7C]`,
  light: tw`bg-[#F2E5FF] disabled:bg-[#F2E5FF] hover:bg-[#E2D0F4] active:bg-[#CC9AFF]`,
  dark: tw`bg-[#440B7C] disabled:bg-[#440B7C] hover:bg-[#1E0338] active:bg-[#000000]`,
  grey: tw`bg-[#EDEDED] disabled:bg-[#EDEDED] hover:bg-[#DAD9E0] active:bg-[#B4AFC0]`,
  black: tw`bg-[#000000] disabled:bg-[#000000] hover:bg-[#2A2133] active:bg-[#1C1324]`,
  white: tw`bg-[#FFFFFF] disabled:bg-[#FFFFFF] active:bg-[#EDEDED] hover:bg-[#F6F6F6]  border border-solid border-[#EDEDED] hover:border-[#DAD9E0] active:border-[#DAD9E0]`,
  red: tw`bg-[#FFE8EE] disabled:bg-[#FFE8EE] hover:bg-[#FFCBD8] active:bg-[#FFB1C4]`,
  green: tw`bg-[#E6F9E9] disabled:bg-[#E6F9E9] hover:bg-[#caffd2] active:bg-[#a9ffb6]`,
}

const selectedColorsStyles = {
  primary: tw`bg-[#440B7C]`,
  light: tw`bg-[#CC9AFF]`,
  dark: tw`bg-[#1B0131]`,
  grey: tw`bg-[#B4AFC0]`,
  black: tw`bg-[#1C1324]`,
  white: tw`bg-[#EDEDED]`,
  red: tw`bg-[#FFB1C4]`,
  green: tw`bg-[#a9ffb6]`,
}

export const contentColors = {
  primary: '#FFFFFF',
  light: '#7F00FF',
  dark: '#FFFFFF',
  grey: '#000000',
  black: '#FFFFFF',
  white: '#000000',
  red: '#FF1A51',
  green: '#03B620',
}

const textColorsStyles = {
  primary: tw`text-[#FFFFFF]`,
  light: tw`text-[#7F00FF]`,
  dark: tw`text-[#FFFFFF]`,
  grey: tw`text-[#000000]`,
  black: tw`text-[#FFFFFF]`,
  white: tw`text-[#000000]`,
  red: tw`text-[#FF1A51]`,
  green: tw`text-[#03B620]`,
}

const textSizeStyles = {
  large: tw`text-[16px]`,
  medium: tw`text-[16px]`,
  small: tw`text-[14px]`,
  tiny: tw`text-[14px]`,
}

const ButtonComponent = ({
  children,
  leftIcon,
  rightIcon,
  className,
  round,
  selected,
  disabled,
  loading,
  size = 'large',
  color = 'primary',
  ...rest
}: ButtonProps) => (
  <LoadingButton
    {...rest}
    loading={loading}
    disabled={disabled}
    css={[
      tw`min-w-0 px-4`,
      !children && tw`px-2`,
      sizeStyles[size],
      colorsStyles[color],
      selected && selectedColorsStyles[color],
      round && tw`rounded-[40px]`,
      className,
    ]}
  >
    {leftIcon && !loading ? (
      <div css={[tw`mr-2 h-5`, disabled && tw`opacity-50`, !children && tw`mr-0`]}>{leftIcon}</div>
    ) : (
      <div css={children && tw`w-1`} />
    )}
    {children && (
      <Typography
        css={[
          tw`font-semibold`,
          textColorsStyles[color],
          textSizeStyles[size],
          loading && tw`opacity-0`,
          disabled && tw`opacity-50`,
        ]}
      >
        {children}
      </Typography>
    )}
    {rightIcon && !loading ? (
      <div css={[tw`ml-auto pl-2 h-5`, disabled && tw`opacity-50`, !children && tw`ml-0`]}>
        {rightIcon}
      </div>
    ) : (
      <div css={children && tw`w-1`} />
    )}
  </LoadingButton>
)

export const Button = memo(ButtonComponent)
