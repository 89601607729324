import { useCallback, useEffect } from 'react'
import {
  FetchDashboardsUpdates,
  FetchFailedPaymentsUpdates,
  FetchMarkets,
  FetchOnboardingTemplates,
  FetchTags,
  FetchUser,
} from 'src/constants/actionTypes'
import { useAsyncDispatchWithErrorToast } from './useAsyncDispatchWithErrorToast'
import { AccessInfo, User } from 'src/interfaces/user'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { UserPermission, UserProductName } from 'src/constants/user'
import { usePolling } from 'src/utils/hooks/usePolling'
import { useSelector } from 'react-redux'
import { getAccessInfo } from 'src/selectors/user'
import { boot as bootIntercom } from '@intercom/messenger-js-sdk'

const DASHBOARDS_UPDATES_POLLING_INTERVAL = 1000 * 60 * 3

export const useFetchUserAndData = () => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  const accessInfo = useSelector(getAccessInfo)

  const fetchUserAndMarkets = useCallback(async () => {
    const user = await asyncDispatchWithErrorToast<{ user: User; accessInfo: AccessInfo } | null>(
      FetchUser.request(),
    )

    if (!user) {
      return
    }

    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      bootIntercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user.user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: `${user.user.firstName} ${user.user.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      })
    }

    asyncDispatchWithErrorToast(FetchMarkets.request({ partnerId: user.user.partner.id }))
    asyncDispatchWithErrorToast(FetchTags.request())
    asyncDispatchWithErrorToast(
      FetchOnboardingTemplates.request({ partnerId: user.user.partner.id }),
    )
    if (
      user.accessInfo?.permission?.includes(UserPermission.PaymentsView) &&
      user.accessInfo?.products?.some((item) => item.product === UserProductName.Payments)
    ) {
      asyncDispatchWithErrorToast(FetchFailedPaymentsUpdates.request())
    }
    asyncDispatchWithErrorToast(
      FetchDashboardsUpdates.request({ type: DashboardsUpdatesType.DisabledContractors }),
    )
    asyncDispatchWithErrorToast(
      FetchDashboardsUpdates.request({ type: DashboardsUpdatesType.WaitingForApproval }),
    )
  }, [])

  const handleFetchUpdates = useCallback(async () => {
    if (
      accessInfo?.permission?.includes(UserPermission.PaymentsView) &&
      accessInfo?.products?.some((item) => item.product === UserProductName.Payments)
    ) {
      asyncDispatchWithErrorToast(FetchFailedPaymentsUpdates.request())
    }
    asyncDispatchWithErrorToast(
      FetchDashboardsUpdates.request({ type: DashboardsUpdatesType.DisabledContractors }),
    )
    asyncDispatchWithErrorToast(
      FetchDashboardsUpdates.request({ type: DashboardsUpdatesType.WaitingForApproval }),
    )
  }, [accessInfo])

  const { startPolling, stopPolling } = usePolling(
    handleFetchUpdates,
    DASHBOARDS_UPDATES_POLLING_INTERVAL,
  )

  useEffect(() => {
    if (!accessInfo) {
      return
    }

    startPolling()

    return () => {
      stopPolling()
    }
  }, [accessInfo])

  return { fetchUserAndMarkets }
}
