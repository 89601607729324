import { memo } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { ContractorDeactivationReason, ContractorStatus } from 'src/constants/contractor/enums'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { ContractorDeactivationReasonTooltip } from '../ContractorDeactivationReasonTooltip'
import { Typography } from '@mui/material'
import { contractorStatusTitles } from 'src/constants/contractor/constants'

interface Props {
  status: ContractorStatus
  deactivationReason?: ContractorDeactivationReason | null
  deactivatedByEmail?: string | null
  expiredSteps?: string[]
  failedSteps?: string[]
  deactivatedAt?: string | null
}

const contractorStatusMap = {
  [ContractorStatus.Active]: {
    color: colors.COLORS_GREEN_500,
    text: contractorStatusTitles[ContractorStatus.Active],
    bgStyle: tw`bg-[rgba(230, 249, 233, 1)]`,
  },
  [ContractorStatus.Deactivated]: {
    color: colors.COLORS_RED_500,
    text: contractorStatusTitles[ContractorStatus.Deactivated],
    bgStyle: tw`bg-[rgba(255, 232, 238, 1)]`,
  },
  [ContractorStatus.ExpiresSoon]: {
    color: colors.COLORS_ORANGE_500,
    text: contractorStatusTitles[ContractorStatus.ExpiresSoon],
    bgStyle: tw`bg-[#FFEED6]`,
  },
  [ContractorStatus.Unknown]: {
    color: colors.SHADES_GREY_500,
    text: contractorStatusTitles[ContractorStatus.Unknown],
    bgStyle: tw`bg-[rgba(218, 217, 224, 1)]`,
  },
}

const ContractorStatusChipComponent = ({
  status,
  deactivationReason,
  deactivatedByEmail,
  expiredSteps,
  failedSteps,
  deactivatedAt,
}: Props) => (
  <div css={tw`flex items-center`}>
    <div css={tw`px-2 py-1 rounded-[10px]`}>
      <Chip
        className={contractorStatusMap[status].bgStyle}
        textColor={contractorStatusMap[status].color}
        text={contractorStatusMap[status].text}
      />
    </div>
    {status === ContractorStatus.Deactivated && deactivationReason && (
      <ContractorDeactivationReasonTooltip
        deactivationReason={deactivationReason}
        deactivatedByEmail={deactivatedByEmail}
        deactivatedAt={deactivatedAt}
        expiredSteps={expiredSteps}
        failedSteps={failedSteps}
      >
        <Typography fontWeight={500} variant="subtitle1" color="black" css={tw`ml-2 underline`}>
          Details
        </Typography>
      </ContractorDeactivationReasonTooltip>
    )}
  </div>
)

export const ContractorStatusChip = memo(ContractorStatusChipComponent)
