import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { OnboardingFailureReasonTooltip } from '../OnboardingFailureReasonTooltip'

interface Props {
  onboardingStatus: OnboardingStatus
  failedAt?: string | null
  failedSteps?: string[]
  className?: any
}

const createdLabel = {
  textStyle: tw`text-[#0056FF]`,
  containerStyle: tw`bg-[#D8E5FF]`,
  text: 'Onboarding pending',
}

const waitingForApprovalLabel = {
  textStyle: tw`text-[#FF6320]`,
  containerStyle: tw`bg-[#FFEED6]`,
  text: 'Waiting for approval',
}

const stepsLabelMap = {
  [OnboardingStatus.WaitingForApproval]: waitingForApprovalLabel,
  [OnboardingStatus.Completed]: {
    textStyle: tw`text-[#03B620]`,
    containerStyle: tw`bg-[#E6F9E9]`,
    text: 'Completed',
  },
  [OnboardingStatus.Created]: createdLabel,
  [OnboardingStatus.PartiallyCompleted]: createdLabel,
  [OnboardingStatus.Failed]: {
    textStyle: tw`text-[#FF1A51]`,
    containerStyle: tw`bg-[#FFE8EE]`,
    text: 'Onboarding failed',
  },
}

const OnboardingStatusChipComponent = ({
  onboardingStatus,
  className,
  failedAt,
  failedSteps = [],
}: Props) => {
  const params = stepsLabelMap[onboardingStatus]

  if (!params) {
    return null
  }

  return (
    <div css={tw`flex items-center`}>
      <div css={[tw`px-2 py-1 rounded-[10px]`, params.containerStyle, className]}>
        <Typography css={params.textStyle} variant="subtitle2">
          {params.text}
        </Typography>
      </div>
      {onboardingStatus === OnboardingStatus.Failed && !!(failedAt || failedSteps.length) && (
        <OnboardingFailureReasonTooltip failedAt={failedAt} failedSteps={failedSteps}>
          <Typography fontWeight={500} variant="subtitle1" color="black" css={tw`ml-2 underline`}>
            Details
          </Typography>
        </OnboardingFailureReasonTooltip>
      )}
    </div>
  )
}

export const OnboardingStatusChip = memo(OnboardingStatusChipComponent)
