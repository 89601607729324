import tw from 'twin.macro'
import {
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridRowCountSelector,
  gridPageSizeSelector,
} from '@mui/x-data-grid-pro'
import { Button } from 'src/components/shared/Button'
import { ChevronLeftMinor, ChevronRightMinor, ImportMinor } from '@shopify/polaris-icons'
import { Typography } from '@mui/material'
import { ActionButton } from 'src/components/shared/ActionButton'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { colors } from 'src/theme'

interface Props {
  pageSizeOptions: number[]
}

export const GridTableFooter = ({ pageSizeOptions }: Props) => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const rowsCount = useGridSelector(apiRef, gridRowCountSelector)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return pageSizeOptions.map((item) => ({
      label: `${item}`,
      onClick: () => {
        apiRef.current.setPageSize(item)
      },
    }))
  }, [pageSizeOptions, apiRef])

  const handleClickPageLeft = useCallback(() => {
    apiRef.current.setPage(page - 1)
  }, [apiRef, page])

  const handleClickPageRight = useCallback(() => {
    apiRef.current.setPage(page + 1)
  }, [apiRef, page])

  const handleExport = useCallback(() => {
    apiRef.current.exportDataAsCsv()
  }, [apiRef])

  return (
    <div
      css={tw`py-2 px-4 flex justify-between w-full bg-white border-0 border-t border-solid border-[#EDEDED]`}
    >
      <div css={tw`flex flex-row items-center`}>
        <Button
          disabled={page === 0}
          size="tiny"
          color="grey"
          className={tw`rounded-r-none`}
          leftIcon={<ChevronLeftMinor width={20} height={20} />}
          onClick={handleClickPageLeft}
        />
        <Button
          disabled={(page + 1) * pageSize > rowsCount}
          size="tiny"
          color="grey"
          className={tw`rounded-l-none`}
          leftIcon={<ChevronRightMinor width={20} height={20} />}
          onClick={handleClickPageRight}
        />
        <Typography variant="subtitle1" color={colors.SHADES_GREY_400} css={tw`ml-2`}>
          {`${page * pageSize + 1}-${Math.min(
            (page + 1) * pageSize,
            rowsCount,
          )} of ${rowsCount} results`}
        </Typography>
      </div>
      <div css={tw`flex flex-row items-center`}>
        <div css={tw`flex flex-row items-center mr-2`}>
          <Typography variant="subtitle1" color={colors.SHADES_GREY_400} css={tw`mr-2`}>
            Rows per page
          </Typography>
          <ActionButton
            selected={!!anchorEl}
            size="tiny"
            color="grey"
            text={`${pageSize}`}
            onClick={handleClick}
          />
          <ActionMenu
            anchorEl={anchorEl}
            items={actionItems}
            isOpen={isMenuOpen}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleCloseMenu}
          />
        </div>
        <Button
          size="tiny"
          color="grey"
          rightIcon={<ImportMinor width={20} height={20} />}
          onClick={handleExport}
        >
          Export table
        </Button>
      </div>
    </div>
  )
}
