import {
  GridCallbackDetails,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React, { memo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { paymentColumns } from 'src/constants/payment/paymentGrid'
import { Payment } from 'src/interfaces/payment'
import tw from 'twin.macro'
import { EmptyPayments } from './EmptyPayments'
import { DropdownMinor } from '@shopify/polaris-icons'
import { GridTableFooter } from 'src/components/shared/grid/GridTableFooter'

const handleGetRowId = (row: Payment) => row.id

interface Props {
  payments: Array<Payment>
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  rowSelectionModel: GridRowSelectionModel
  sortModel?: GridSortModel
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel): void
}

const PaymentsGridComponent = ({
  payments,
  isLoading,
  paginationModel,
  pageSizeOptions,
  rowCount,
  sortModel,
  rowSelectionModel,
  onPaginationModelChange,
  onSortModelChange,
  onRowSelectionModelChange,
}: Props) => {
  return (
    <div
      css={[
        tw`w-full h-[400px]`,
        {
          '@media (min-height: 830px)': tw`h-[520px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        checkboxSelection
        rows={payments}
        columns={paymentColumns}
        rowSelectionModel={rowSelectionModel}
        loading={isLoading}
        pagination
        paginationMode="server"
        sortingMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        pageSizeOptions={pageSizeOptions}
        rowHeight={44}
        columnHeaderHeight={36}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        getRowId={handleGetRowId}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onSortModelChange={onSortModelChange}
        slots={{
          noResultsOverlay: EmptyPayments,
          noRowsOverlay: EmptyPayments,
          columnSortedDescendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnSortedAscendingIcon: () => (
            <DropdownMinor fill="#655E7C" css={tw`rotate-180`} width={20} />
          ),
          columnUnsortedIcon: () => null,
          footer: () => <GridTableFooter pageSizeOptions={pageSizeOptions} />,
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const PaymentsGrid = memo(PaymentsGridComponent)
