import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { ActionButton } from '../../shared/ActionButton'
import { ActionMenu } from '../../shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { CirclePlusMinor, InviteMinor, LinkMinor, SaveMinor } from '@shopify/polaris-icons'
import { InviteWorkersModal } from './InviteWorkersModal'
import { InviteWorkersViaLinkForm } from './invite-workers-via-link-form/InviteWorkersViaLinkForm'
import { InviteWorkersViaMessageForm } from './invite-workers-via-message-form/InviteWorkersViaMessageForm'
import { InviteWorkersViaCSVForm } from './invite-workers-via-csv-form/InviteWorkersViaCSVForm'
import tw from 'twin.macro'

const refreshPage = () => {
  window.location.reload()
}

const InviteWorkersButtonComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isInviteViaMessageOpen, setIsInviteViaMessageOpen] = useState(false)
  const [isInviteViaLinkOpen, setIsInviteViaLinkOpen] = useState(false)
  const [isInviteViaCSVOpen, setIsInviteViaCSVOpen] = useState(false)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleCloseInviteViaMessage = useCallback(() => {
    setIsInviteViaMessageOpen(false)
  }, [])

  const handleCloseInviteViaLink = useCallback(() => {
    setIsInviteViaLinkOpen(false)
  }, [])

  const handleCloseInviteViaCSV = useCallback(() => {
    setIsInviteViaCSVOpen(false)
  }, [])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return [
      {
        label: 'Invite contractor via message',
        icon: <InviteMinor width={20} />,
        onClick: () => {
          setIsInviteViaMessageOpen(true)
          setAnchorEl(null)
        },
      },
      {
        label: 'Invite contractor via link',
        icon: <LinkMinor width={20} />,
        onClick: () => {
          setIsInviteViaLinkOpen(true)
          setAnchorEl(null)
        },
      },
      {
        label: 'Upload CSV',
        icon: <SaveMinor width={20} />,
        onClick: () => {
          setIsInviteViaCSVOpen(true)
          setAnchorEl(null)
        },
      },
    ]
  }, [])

  return (
    <>
      <ActionButton
        text="Invite contractors"
        selected={!!anchorEl}
        className={tw`px-4`}
        color="dark"
        icon={<CirclePlusMinor fill="white" width={20} />}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        itemClassName={tw`w-[232px]`}
        onClose={handleCloseMenu}
      />
      <InviteWorkersModal
        title="Invite contractor via message"
        isOpen={isInviteViaMessageOpen}
        onClose={handleCloseInviteViaMessage}
      >
        <InviteWorkersViaMessageForm onSubmit={refreshPage} onClose={handleCloseInviteViaMessage} />
      </InviteWorkersModal>
      <InviteWorkersModal
        title="Invite contractor via link"
        isOpen={isInviteViaLinkOpen}
        onClose={handleCloseInviteViaLink}
      >
        <InviteWorkersViaLinkForm />
      </InviteWorkersModal>
      <InviteWorkersModal
        title="Upload CSV"
        isOpen={isInviteViaCSVOpen}
        onClose={handleCloseInviteViaCSV}
      >
        <InviteWorkersViaCSVForm onSubmit={refreshPage} onClose={handleCloseInviteViaCSV} />
      </InviteWorkersModal>
    </>
  )
}

export const InviteWorkersButton = memo(InviteWorkersButtonComponent)
