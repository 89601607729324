export enum StitchStatus {
  Created = 'created',
}

export enum ContractorStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  ExpiresSoon = 'expires_soon',
  Unknown = 'unknown',
}

export enum ContractorState {
  Active = 'active',
  Deactivated = 'deactivated',
  Recollection = 'recollection',
}

export enum ContractorDeactivationReason {
  Manual = 'manual',
  Auto = 'auto',
  GracePeriod = 'grace_period',
  ExpiredDocuments = 'expired_documents',
  FailedDocument = 'failed_document',
}
