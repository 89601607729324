import { IconButton, InputAdornment, TextField } from '@mui/material'
import tw from 'twin.macro'
// import { ActionButton } from 'src/components/shared/ActionButton'
import { CircleCancelMinor, SearchMinor } from '@shopify/polaris-icons'
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PaymentsContext } from './Payments.context'
import _ from 'lodash'
import { PaymentsBatchActionButton } from './action/PaymentsBatchActionButton'
import { PaymentStatusSelect } from './PaymentStatusSelect'

export const PaymentsHeader = () => {
  const { setSearchQuery, searchQuery } = useContext(PaymentsContext)

  const searchDebounce = useRef(_.debounce(setSearchQuery, 700))

  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }, [])

  useEffect(() => {
    searchDebounce.current(searchValue)
  }, [searchValue])

  const handleClear = useCallback(() => {
    setSearchValue('')
    setSearchQuery('')
  }, [])

  return (
    <div
      css={tw`flex flex-row items-center justify-between px-4 py-3 bg-white rounded-t-lg border-0 border-b border-solid border-[#EDEDED]`}
    >
      <div css={tw`flex flex-row items-center gap-4`}>
        <div css={tw`bg-white flex rounded-lg flex-row overflow-hidden w-[400px]`}>
          <TextField
            placeholder="Search"
            type="text"
            variant="outlined"
            fullWidth
            value={searchValue}
            size="small"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchMinor width={20} />
                </InputAdornment>
              ),

              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton css={tw`mr-[-12px] rounded-none`} onClick={handleClear}>
                    <CircleCancelMinor width={20} fill="#B4AFC0" />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                '&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid var(--Shades-Grey-100, #EDEDED)',
                },
              },
            }}
          />
        </div>
        <PaymentStatusSelect />
      </div>
      <PaymentsBatchActionButton />
    </div>
  )
}
