import Menu from '@mui/material/Menu'
import React, { memo, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ProductsMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { ActionButton } from 'src/components/shared/ActionButton'
import { useSelector } from 'react-redux'
import { getTags } from 'src/selectors/tag'

interface Props {
  selectedTags: Array<string>
  setSelectedTags(statusFilter: Array<string>): void
  openManageTags(): void
}

const TagSelectComponent = ({ selectedTags, setSelectedTags, openManageTags }: Props) => {
  const tags = useSelector(getTags)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggle = useCallback(
    (value: string) => {
      const currentIndex = selectedTags.indexOf(value)

      if (currentIndex === -1) {
        setSelectedTags([...selectedTags, value])
      } else {
        setSelectedTags([
          ...selectedTags.slice(0, currentIndex),
          ...selectedTags.slice(currentIndex + 1),
        ])
      }
    },
    [selectedTags],
  )

  const handleClear = useCallback(() => {
    setSelectedTags([])
  }, [])

  const getItemChecked = useCallback(
    (tag: string) => {
      return selectedTags.includes(tag)
    },
    [selectedTags],
  )

  const handleOpenManageTags = useCallback(() => {
    handleClose()
    openManageTags()
  }, [openManageTags])

  return (
    <>
      <ActionButton
        className={tw`mr-4 px-4`}
        selected={!!anchorEl}
        color="white"
        text="Tags"
        icon={
          selectedTags?.length ? (
            <div css={tw`w-5 h-5 items-center justify-center flex rounded bg-[#7F00FF]`}>
              <Typography variant="overline" color="white">
                {selectedTags.length}
              </Typography>
            </div>
          ) : (
            <ProductsMinor width={20} />
          )
        }
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        css={tw`mt-2 rounded-xl`}
      >
        {tags.length ? (
          <div>
            <div css={tw`px-4 py-2 flex items-center justify-between`}>
              <Typography variant="h5">Select:</Typography>
              {selectedTags?.length ? (
                <div css={tw`cursor-pointer hover:opacity-50`} onClick={handleClear}>
                  <Typography variant="subtitle1" color={colors.PRIMARY_PURPLE_500}>
                    Clear all
                  </Typography>
                </div>
              ) : null}
            </div>
            <List>
              {tags.map(({ name }) => {
                const labelId = `checkbox-list-label-${name}`

                return (
                  <ListItem css={tw`w-[296px]`} key={name} disablePadding>
                    <ListItemButton role={undefined} onClick={() => handleToggle(name)} dense>
                      <ListItemIcon css={tw`min-w-0 mr-2`}>
                        <Checkbox
                          edge="start"
                          checked={getItemChecked(name)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={<Typography variant="subtitle1">{name}</Typography>}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </div>
        ) : (
          <div css={tw`px-6 py-4 w-[240px]`}>
            <Typography css={tw`text-center mb-0.5`} variant="h4">
              No tags were created
            </Typography>
            <Typography css={tw`text-center`} variant="body2" color={colors.SHADES_GREY_400}>
              Create a new tag to manage your fleet efficiently
            </Typography>
            <Typography
              css={tw`text-center cursor-pointer mt-2`}
              color={colors.PRIMARY_PURPLE_500}
              variant="subtitle1"
              onClick={handleOpenManageTags}
            >
              Manage tags
            </Typography>
          </div>
        )}
      </Menu>
    </>
  )
}

export const TagSelect = memo(TagSelectComponent)
