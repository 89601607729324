import React, { useCallback, useMemo, useState } from 'react'
import { InputAdornment, OutlinedTextFieldProps, TextField } from '@mui/material'
import { HideMinor, ViewMinor } from '@shopify/polaris-icons'
import tw from 'twin.macro'

export const PasswordTextField = ({ InputProps = {}, ...props }: OutlinedTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  const Icon = useMemo(() => (showPassword ? ViewMinor : HideMinor), [showPassword])

  return (
    <TextField
      {...props}
      type={showPassword ? undefined : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              width={20}
              fill="#B4AFC0"
              onClick={togglePassword}
              css={tw`hover:fill-[#7F00FF] cursor-pointer`}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}
