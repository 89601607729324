import { Tooltip, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import React from 'react'

interface Props {
  title: string
  content?: ReactNode
  children: ReactNode
}

export const TitledTooltip = ({ content, title, children }: Props) => {
  return (
    <Tooltip
      color="black"
      title={
        <>
          <Typography css={tw`mb-1`} variant="h6" color={colors.SHADES_GREY_200}>
            {title}
          </Typography>
          {content}
        </>
      }
      placement="right-end"
    >
      <span css={tw`h-5 cursor-pointer`}>{children}</span>
    </Tooltip>
  )
}
