import { memo, ReactNode } from 'react'
import { CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { Button, contentColors, ButtonProps } from './Button'

interface Props extends ButtonProps {
  icon?: ReactNode
  text: string
}

const ActionButtonComponent = ({
  icon,
  text,
  variant = 'contained',
  size = 'small',
  color = 'primary',
  selected,
  ...props
}: Props) => (
  <Button
    {...props}
    variant={variant}
    size={size}
    color={color}
    leftIcon={icon}
    selected={selected}
    rightIcon={
      selected ? (
        <CaretUpMinor width={20} fill={contentColors[color]} />
      ) : (
        <CaretDownMinor width={20} fill={contentColors[color]} />
      )
    }
  >
    {text}
  </Button>
)

export const ActionButton = memo(ActionButtonComponent)
