import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ReactNode } from 'react'
import { Payment } from 'src/interfaces/payment'
import { colors } from 'src/theme'
import { TitledTooltip } from '../shared/TitledTooltip'
import { NumericList } from '../shared/NumericList'
import React from 'react'
import { ContractorDeactivationReason } from 'src/constants/contractor/enums'
import { SingleFailureReasonType } from 'src/constants/payment/enums'

interface Props {
  payment: Payment
  children: ReactNode
}

export const PaymentFailureReasonTooltip = ({ payment, children }: Props) => {
  if (payment.failureReasonType === SingleFailureReasonType.BadPayment && payment.failureReason) {
    return (
      <TitledTooltip
        title="Bad payment"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              This payment needs to be re-uploaded by the customer when the next issues are resolved
            </Typography>
            <NumericList
              variant="body2"
              color={colors.SHADES_GREY_300}
              texts={[payment.failureReason]}
            />
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (payment.failureReasonType === SingleFailureReasonType.ContractorNotOnboarded) {
    return (
      <TitledTooltip
        title="Contractor is not onboarded"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              This payment needs to be re-uploaded by the customer when the contractor finishes
              their onboarding
            </Typography>
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (payment.failureReasonType === SingleFailureReasonType.ContractorDeactivated) {
    return (
      <TitledTooltip
        title="Contractor is deactivated"
        content={
          <>
            {!!payment.deactivatedAt && (
              <Typography variant="body2" color={colors.SHADES_GREY_300}>
                {`Deactivated at ${dayjs(payment.deactivatedAt).format('MMM D, h:mm A')} due to ${
                  payment.deactivationReason === ContractorDeactivationReason.Manual
                    ? 'manual'
                    : 'automatic'
                } deactivation${
                  payment.deactivationReason === ContractorDeactivationReason.Manual
                    ? ' by the customer'
                    : ''
                }`}
              </Typography>
            )}
            <NumericList
              variant="body2"
              color={colors.SHADES_GREY_300}
              texts={[
                payment.deactivationReason === ContractorDeactivationReason.Manual
                  ? 'Contractor needs to be re-activated by the customer before they can receive payments'
                  : 'Contractor needs to update their information before they can be re-activated. Contractor already received the necessary communication to do it.',
                'This payment needs to be re-uploaded by the customer when the contractor is re-activated',
              ]}
            />
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (payment.failureReasonType === SingleFailureReasonType.NoValidPaymentMethod) {
    return (
      <TitledTooltip
        title="No valid payment method on file"
        content={
          <NumericList
            variant="body2"
            color={colors.SHADES_GREY_300}
            texts={[
              'This payment needs to be re-uploaded by the customer when the contractor finishes adding their payment method',
              'Customer can re-send an invite to help the contractor locate page to update their payment method',
            ]}
          />
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (payment.failureReasonType === SingleFailureReasonType.UnsuccessfulPaymentAttempt) {
    return (
      <TitledTooltip
        title="Payment attempt was unsuccessful"
        content={
          <NumericList
            variant="body2"
            color={colors.SHADES_GREY_300}
            texts={[
              'The contractor already received communications with instructions on how to update their payment method',
              'This payment needs to be re-uploaded by the customer when the contractor finishes updating their payment method',
            ]}
          />
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  return (
    <TitledTooltip
      title="Reason unknown"
      content={
        <>
          <Typography variant="body2" color={colors.SHADES_GREY_300}>
            Please contact support
          </Typography>
        </>
      }
    >
      {children}
    </TitledTooltip>
  )
}
